import * as React from "react";

interface State {
  ready: boolean;
  services: Array<{ id: string, name: string }>;
}

export class ServiceOverview extends React.Component<{}, State> {
  public state: State;

  constructor(props: any) {
    super(props);

    this.state = {
      ready: false,
      services: []
    };

    // in case we have no user
    let user;

    // try to parse parameters
    const params = window.location.search.substr(1).split("=");

    if (params.length >= 2) {
      if (params[0] === "user") {
        user = params[1];
      }
    }

    fetch("/api/services" + ((user !== undefined) ? "?user=" + user : "")).then(
      (result) => {
        if (result.status !== 200) {
          if (result.status === 401) {
            console.log("Info, request was unauthorized, redirect to login page");
            //window.location.href = "/sso/login?redirect=/userdetails";
            return;
          }
          // general error

          console.log ("Error, request was not successful");
          return;
        }

        result.body.getReader().read().then(({ done, value }) => {
          if (done) {
            console.log ("Error, end of stream reached.");
            return;
          }
          const serviceList = JSON.parse(new TextDecoder("utf-8").decode(value));

          console.log ("Received: " + JSON.stringify (serviceList));

          this.setState({ ...this.state, services: serviceList, ready: true });
        });
      }
    );
  }

  public render() {
    if (!this.state.ready) {
      return <div>Loading</div>;
    }

    const result: JSX.Element[] = [];

    for (const service of this.state.services) {
      result.push(<a href={ "/redir/auth?service=" + service.id }>{ service.name }</a>);
      result.push(<br/>);
    }

    return result.slice(0, result.length - 1);
  }
}
